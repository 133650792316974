import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'dashboard', meta: { requiresAuth: true }, component: () => import('../views/Dashboard/DashboardView.vue') },
  { path: '/notifications', name: 'notifications', meta: { requiresAuth: true }, component: () => import('../views/Notifications/NotificationsView.vue') },
  { path: '/activities', name: 'activities', meta: { requiresAuth: true }, component: () => import('../views/Activities/ActivitiesView.vue') },
  { path: '/settings', name: 'settings', meta: { requiresAuth: true }, component: () => import('../views/Settings/SettingsView.vue') },
  { path: '/applications', name: 'applications', meta: { requiresAuth: true }, component: () => import('../views/Applications/ApplicationsView.vue') },
  { path: '/applications/:id', name: 'edit-application', meta: { requiresAuth: true }, component: () => import('../views/Applications/EditApplication.vue') },
  { path: '/roles', name: 'roles', meta: { requiresAuth: true }, component: () => import('../views/Roles/RolesView.vue') },
  { path: '/roles/:id', name: 'edit-role', meta: { requiresAuth: true }, component: () => import('../views/Roles/EditRole.vue') },
  { path: '/users', name: 'users', meta: { requiresAuth: true }, component: () => import('../views/Users/UsersView.vue') },
  { path: '/users/:username', name: 'edit-user', meta: { requiresAuth: true }, component: () => import('../views/Users/EditUser.vue') },
  { path: '/admins', name: 'admins', meta: { requiresAuth: true }, component: () => import('../views/Admins/AdminsView.vue') },
  { path: '/admins/:id', name: 'edit-admin', meta: { requiresAuth: true }, component: () => import('../views/Admins/EditAdmin.vue') },
  { path: '/social-platforms', name: 'social-platforms', meta: { requiresAuth: true }, component: () => import('../views/SocialPlatforms/SocialPlatformsView.vue') },
  { path: '/social-platforms/:id', name: 'edit-social-platform', meta: { requiresAuth: true }, component: () => import('../views/SocialPlatforms/EditSocialPlatform.vue') },
  { path: '/login', name: 'login', component: () => import('../views/Auth/LoginView.vue') },
  { path: '/logout', name: 'logout', meta: { requiresAuth: true }, component: () => import('../views/Auth/LogoutView.vue') },
  { path: '/recover', name: 'recover', component: () => import('../views/Auth/RecoverView.vue') },
  { path: '*', component: () => import('../views/404View.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (to.name === 'login' || to.name === 'recover') {
      next({ name: 'dashboard' });
    } else {
      next();
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

export default router
