<template>
  <nav
    class="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 h-[63px] flex relative items-center">
    <div class="absolute top-[63px] sm:top-0 right-0 left-0 flex flex-row justify-center z-10" v-if="$store.getters.isMaintenanceActive">
      <RouterLink :to="{ name: 'settings', hash: '#maintenance' }"
        class="shadow-md bg-yellow-100 text-yellow-800 hover:text-yellow-500 text-xs font-medium me-2 px-2.5 py-1 rounded-md rounded-t-none dark:bg-yellow-900 dark:text-yellow-300 dark:hover:text-yellow-200 flex flex-row items-center border border-t-0 border-transparent hover:border-yellow-200/40">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
          class="bi bi-wrench-adjustable-circle w-4 h-4 me-2" viewBox="0 0 16 16">
          <path d="M12.496 8a4.5 4.5 0 0 1-1.703 3.526L9.497 8.5l2.959-1.11q.04.3.04.61" />
          <path
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-1 0a7 7 0 1 0-13.202 3.249l1.988-1.657a4.5 4.5 0 0 1 7.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.5 4.5 0 0 1-1.592-.29L4.747 14.2A7 7 0 0 0 15 8m-8.295.139a.25.25 0 0 0-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 0 0 .287.376l.808-.27-.595.894a.25.25 0 0 0 .287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 0 0-.288-.376l-.808.27z" />
        </svg>
        <div class="text-xs">Maintenance active</div>
      </RouterLink>
    </div>
    <div class="px-3 py-3 lg:px-5 lg:pl-3 w-full">
      <div class="flex items-center justify-between w-full">
        <div class="flex items-center justify-start w-full">
          <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar"
            class="p-1 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <svg id="toggleSidebarMobileHamburger" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
            </svg>
            <svg id="toggleSidebarMobileClose" class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
          <RouterLink :to="{ name: 'dashboard' }" class="flex ml-3 relative">
            <Logo class="-mt-4" />
            <div class="text-xs absolute top-4 left-9 uppercase font-medium text-gray-300 dark:text-gray-500">
              Administration
            </div>
          </RouterLink>
          <form @submit.stop.prevent="" class="hidden lg:block lg:pl-[90px] flex flex-grow">
            <label for="topbar-search" class="sr-only">Search</label>
            <div class="relative lg:w-96">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              <input type="search" name="search" id="topbar-search" ref="topbarSearch" @blur="stop_search"
                @click="start_search" v-model="search_value"
                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 z-10">
              <div class="absolute top-[11px] right-3 text-xs dark:text-gray-400 text-gray-400"
                @click.prevent="start_search" v-show="!search_active">
                Press <kbd
                  class="px-1 py-0.5 pb-0 text-xs font-semibold text-gray-400 bg-gray-100 border border-gray-200 rounded-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500">Ctrl</kbd>
                + <kbd
                  class="px-1 py-0.5 pb-0 text-xs font-semibold text-gray-400 bg-gray-100 border border-gray-200 rounded-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-500">Space</kbd>
                to start a search
              </div>
            </div>
          </form>
        </div>
        <div class="flex items-center">
          <button id="toggleSidebarMobileSearch" type="button"
            class="p-1 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
            <span class="sr-only">Search</span>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
          <button type="button" data-dropdown-toggle="notification-dropdown" id="notification-toggle"
            class="relative p-1 sm:p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
            <span class="sr-only">View notifications</span>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z">
              </path>
            </svg>
            <div
              class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900"
              v-if="$store.getters.getNewNotificationsCount > 0">{{ $store.getters.getNewNotificationsCount }}</div>
          </button>
          <div
            class="z-20 z-50 hidden max-w-sm my-4 overflow-hidden text-base list-none bg-white divide-y divide-gray-100 rounded shadow-lg dark:divide-gray-600 dark:bg-gray-700"
            id="notification-dropdown">
            <div
              class="block px-4 py-2 text-base font-medium text-center text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              Notifications
            </div>
            <div>
              <a href="#" @click.prevent=""
                class="flex px-4 py-3 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600"
                v-for="notification in notifications">
                <div class="flex-shrink-0 relative">
                  <div
                    @click.prevent="go_to_route('edit-user', hide_notifications, { username: notification.entityUser.username })"
                    v-if="notification.entityUser">
                    <div v-if="notification.entityUser.picture">
                      <img class="w-10 h-10 rounded-lg me-4" :src="getPicturePath(notification.entityUser)" alt="icon">
                    </div>
                    <div v-else
                      class="rounded-lg w-10 h-10 bg-gray-200 dark:bg-gray-700 dark:border dark:border-gray-800 flex flex-row items-center justify-center me-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-person-fill icon" viewBox="0 0 16 16">
                        <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      </svg>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      v-if="notification.type == 'user:create'"
                      class="bi bi-person-plus-fill absolute top-7 text-white right-2.5 bg-green-700 rounded-full p-1 h-5 w-5"
                      viewBox="0 0 16 16">
                      <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                      <path fill-rule="evenodd"
                        d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                </div>
                <div class="w-full pl-3">
                  <div class="text-gray-500 font-normal text-sm mb-1 dark:text-gray-400 flex flex-col">
                    <div class="flex-grow">{{ notification.title }}</div>
                  </div>
                  <div class="text-xs" v-html="notification.message"></div>
                  <Timeago :datetime="notification.createdAt" v-if="notification?.createdAt"
                    class="text-xs underline decoration-dotted underline-offset-4 cursor-help"
                    :data-tooltip-target="'tooltip_created_' + notification.id + rand" />
                  <div v-if="notification?.createdAt" :id="'tooltip_created_' + notification.id + rand" role="tooltip"
                    class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-[0_0_8px_rgba(0,0,0,0.5)] opacity-0 tooltip dark:bg-gray-700">
                    {{ new Date(notification.createdAt).toLocaleString() }}
                    <div class="tooltip-arrow" data-popper-arrow></div>
                  </div>
                </div>
              </a>
              <div v-if="notifications.length == 0" class="px-4 py-3 text-center text-gray-500 dark:text-gray-400 w-96">
                No notifications
              </div>
            </div>
            <a href="#" @click.prevent="go_to_route('notifications', hide_notifications)"
              class="block py-2 text-base font-normal text-center text-gray-900 bg-gray-50 hover:bg-gray-100 dark:bg-gray-700 dark:text-white dark:hover:underline">
              <div class="inline-flex items-center ">
                <svg class="w-5 h-5 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                  <path fill-rule="evenodd"
                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                    clip-rule="evenodd"></path>
                </svg>
                View all
              </div>
            </a>
          </div>
          <button id="theme-toggle" data-tooltip-target="tooltip-toggle" type="button"
            class="text-gray-500 hover:dark:text-white dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg text-sm p-1 sm:p-2">
            <svg id="theme-toggle-dark-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
            </svg>
            <svg id="theme-toggle-light-icon" class="hidden w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                fill-rule="evenodd" clip-rule="evenodd"></path>
            </svg>
          </button>
          <div id="tooltip-toggle" role="tooltip"
            class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip ml-2">
            Toggle dark mode
            <div class="tooltip-arrow" data-popper-arrow></div>
          </div>
          <div class="flex items-center ml-1">
            <div>
              <button type="button" class="flex text-sm" id="user-menu-button-2" aria-expanded="false"
                data-dropdown-toggle="user-menu-dropdown">
                <span class="sr-only">Open user menu</span>
                <div
                  class="rounded-lg p-1.5 sm:p-2.5 bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 hover:dark:text-white dark:border dark:border-gray-800 flex flex-row items-center justify-center focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-person-fill icon" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                </div>
              </button>
            </div>
            <div
              class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
              id="user-menu-dropdown">
              <div class="px-4 py-3" role="none">
                <p class="text-sm text-gray-900 dark:text-white" role="none">
                  {{ session_username }}
                </p>
                <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                  {{ session_email }}
                </p>
              </div>
              <ul class="py-1" role="none">
                <li>
                  <a href="#" @click.prevent="go_to_route('dashboard', hide_user_menu)"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white flex flex-row items-center"
                    role="menuitem">
                    <svg class="w-4 h-4 me-2 text-gray-400/90 dark:text-gray-500" fill="currentColor"
                      viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2M3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707M2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8m9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5m.754-4.246a.39.39 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.39.39 0 0 0-.029-.518z" />
                      <path fill-rule="evenodd"
                        d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.95 11.95 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0" />
                    </svg>
                    Dashboard
                  </a>
                </li>
                <li>
                  <a href="#" @click.prevent="go_to_route('settings', hide_user_menu)"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white flex flex-row items-center"
                    role="menuitem">
                    <svg class="w-4 h-4 me-2 text-gray-400/90 dark:text-gray-500" fill="currentColor"
                      viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                    Settings
                  </a>
                </li>
                <li class="border-t border-gray-100 dark:border-gray-600 my-1 pt-1">
                  <a href="#" @click.prevent="go_to_route('logout', hide_user_menu)"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white flex flex-row items-center"
                    role="menuitem">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-power w-4 h-4 me-2 scale-[115%] text-gray-400/90 dark:text-gray-500"
                      viewBox="0 0 16 16">
                      <path d="M7.5 1v7h1V1z" />
                      <path
                        d="M3 8.812a5 5 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812" />
                    </svg>
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import Logo from '@/components/Logo.vue';
import notifications from '@/store/modules/notifications';
import { initFlowbite, Dropdown } from 'flowbite';

export default {
  name: "Navbar",
  components: {
    Logo
  },
  data() {
    return {
      search_value: '',
      search_active: false
    }
  },
  mounted() {
    this.$nextTick(function () {

    })
  },
  created() {
    window.addEventListener('keydown', this.onKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyPress);
  },
  computed: {
    session_username() {
      return this.$store.getters.getUsername;
    },
    session_email() {
      return this.$store.getters.getEmail;
    },
    notifications() {
      return this.$store.getters.getNotifications;
    },
    rand() {
      return Math.random().toString(36).substr(2, 9);
    }
  },
  methods: {
    onKeyPress(event) {
      if (event.ctrlKey && event.key.toLowerCase() === " ") {
        event.preventDefault();
        this.start_search(event);
      }
      else if (event.key.toLowerCase() === "escape") {
        event.preventDefault();
        this.stop_search(event);
        if (this.$refs.topbarSearch) {
          this.$refs.topbarSearch.blur();
        }
      }
    },
    getPicturePath(user) {
      return `${process.env.VUE_APP_API_URL}/pictures/${user.picture}`;
    },
    go_to_route(name, cb, params = {}) {
      if (cb) cb();
      if (this.$route.name === name && JSON.stringify(this.$route.params) === JSON.stringify(params))
        return;
      let route = { name };
      if (Object.keys(params).length > 0)
        route.params = params;
      this.$router.push(route);
    },
    hide_notifications() {
      const dropdown = new Dropdown(document.getElementById('notification-dropdown'), document.getElementById('notification-toggle'), {});
      dropdown.hide();
    },
    hide_user_menu() {
      const userMenuDropdown = document.getElementById('user-menu-dropdown');
      if (userMenuDropdown)
        userMenuDropdown.classList.add('hidden');
    },
    start_search() {
      this.$refs.topbarSearch.focus();
      this.search_active = true;
    },
    stop_search(event) {
      this.search_active = false;
    }
  },
  watch: {
    notifications: {
      handler: function (val) {
        initFlowbite();
      },
      immediate: true
    },
  },
  mounted() {
    this.$nextTick(function () {
      const themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
      const themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

      if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        themeToggleLightIcon.classList.remove('hidden');
      } else {
        themeToggleDarkIcon.classList.remove('hidden');
      }

      const themeToggleBtn = document.getElementById('theme-toggle');

      let event = new Event('dark-mode');

      themeToggleBtn.addEventListener('click', () => {
        themeToggleDarkIcon.classList.toggle('hidden');
        themeToggleLightIcon.classList.toggle('hidden');

        if (localStorage.getItem('color-theme')) {
          if (localStorage.getItem('color-theme') === 'light') {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
            this.$store.dispatch('setTheme', 'dark');
          } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
            this.$store.dispatch('setTheme', 'light');
          }

        } else {
          if (document.documentElement.classList.contains('dark')) {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
            this.$store.dispatch('setTheme', 'light');
          } else {
            document.documentElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
            this.$store.dispatch('setTheme', 'dark');
          }
        }

        document.dispatchEvent(event);
      });
    });
  },
}
</script>
<style scoped>
.navbar-avatar {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
}
</style>