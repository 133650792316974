<template>
    <span class="self-center text-xl font-semibold whitespace-nowrap text-gray-400 dark:text-gray-600">
        <div class="flex flex-row items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" class="text-blue-400/70  translate-y-0.5">
                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                    <path
                        d="M19.875 6.27A2.23 2.23 0 0 1 21 8.218v7.284c0 .809-.443 1.555-1.158 1.948l-6.75 4.27a2.27 2.27 0 0 1-2.184 0l-6.75-4.27A2.23 2.23 0 0 1 3 15.502V8.217c0-.809.443-1.554 1.158-1.947l6.75-3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98z" />
                    <path d="M10 16v-6a2 2 0 1 1 4 0v6m-4-3h4" />
                </g>
            </svg>
            <span class="ms-0.5 text-blue-400/70">uthenticator</span>
        </div>
    </span>
</template>
<script>
export default {
    name: 'Logo',
}
</script>