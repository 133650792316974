import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import session from './modules/session'
import notifications from './modules/notifications'
import activities from './modules/activities'
import settings from './modules/settings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app, 
    session,
    notifications,
    activities,
    settings
  }
})
