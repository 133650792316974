export default {
    state: {
        notifications: []
    },
    getters: {
        getNotifications: state => state.notifications,
        getNewNotificationsCount: state => state.notifications.filter(notification => notification.read === false).length
    },
    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications;
        },
        markAsRead(state, notificationId) {
            state.notifications = state.notifications.map(notification => {
                if (notification.id === notificationId) {
                    notification.read = true;
                }

                return notification;
            });
        },
        markAllAsRead(state) {
            state.notifications = state.notifications.map(notification => {
                notification.read = true;
                return notification;
            });
        }
    },
    actions: {
        async fetchNotifications({commit}, token) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/admins/me/notifications`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    commit('setNotifications', data);
                }
            }
            catch (error) {
                console.error(error);
            }
        },
        async markNotificationAsRead({commit}, {token, notificationId}) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/admins/me/notifications/${notificationId}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    commit('markAsRead', notificationId);
                }
            }
            catch (error) {
                console.error(error);
            }
        },
        async markAllNotificationsAsRead({commit}, token) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/admins/me/notifications`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    commit('markAllAsRead');
                }
            }
            catch (error) {
                console.error(error);
            }
        },
        async deleteAllNotifications({commit}, token) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/admins/me/notifications`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    commit('setNotifications', []);
                }
            }
            catch (error) {
                console.error(error);
            }
        }
    },
}