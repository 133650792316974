<template>
  <div class="h-full w-full overflow-hidden">
    <Navbar v-if="$store.getters.isAuthenticated" />
    <div class="flex overflow-hidden bg-gray-50 dark:bg-gray-900 h-full">
      <Sidebar v-if="$store.getters.isAuthenticated" />
      <div class="w-full h-full overflow-auto bg-gray-50 dark:bg-gray-900"
        :class="{ 'lg:ml-64': $store.getters.isAuthenticated }">
          <router-view></router-view>
        <Bottombar />
      </div>
    </div>
  </div>
</template>
<script>
import { initFlowbite } from 'flowbite';
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Bottombar from '@/components/Bottombar.vue'
import Toasts from '@/components/Toasts.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Sidebar,
    Bottombar,
    Toasts
  },
  mounted() {
    this.$nextTick(async function () {
      initFlowbite();
      const html = document.querySelector('html');
      const theme = localStorage.getItem('color-theme');
      if (theme === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        html.classList.add('dark');
        document.body.classList.add('dark:bg-gray-900');
        document.body.classList.add('dark:text-gray-300');
      } else {
        html.classList.remove('dark');
        document.body.classList.remove('dark:bg-gray-900');
        document.body.classList.remove('dark:text-gray-300');
      }

      if (this.$store.getters.isAuthenticated) {
        await this.$store.dispatch('fetchNotifications', this.$store.getters.getToken);
      }
    });
  }
}
</script>
