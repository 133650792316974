export default {
    state: {
        theme: 'dark',
    },
    getters: {
        getTheme: state => state.theme,
    },
    mutations: {
        setTheme(state, theme) {
            state.theme = theme;
        },
    },
    actions: {
        setTheme({ commit }, theme) {
            commit('setTheme', theme);
        },
    },
}