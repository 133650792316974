export default {
    state: {
        activities: []
    },
    getters: {
        getActivities: state => state.activities,
    },
    mutations: {
        setActivities(state, activities) {
            state.activities = activities;
        },
    },
    actions: {
        async fetchActivities({ commit }, token) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/activities`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    commit('setActivities', data.data);
                }
            }
            catch (error) {
                console.error(error);
            }
        },
    },
}