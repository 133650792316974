import Vue from 'vue'
import VueTimeago from 'vue-timeago'

import App from './App.vue'
import router from './router'
import store from './store'
import './assets/index.css'
import Utils from './utils'
import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';

Vue.config.productionTip = false

const token = localStorage.getItem('token');

if (token) {
  const decoded = Utils.parseJwt(token);
  store.commit('setUsername', decoded.username);
  store.commit('setEmail', decoded.email);
  store.commit('setAdminId', decoded.adminId);
  store.commit('setToken', token);
}

Vue.use(VueTimeago, {
  name: 'Timeago',
  locale: 'en',
  locales: {
    en: enUS,
  },
  converter: (date) => {
    return formatDistanceToNow(new Date(date), {
      addSuffix: true,
      locale: enUS, 
      includeSeconds: true,
    });
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
