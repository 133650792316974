export default {
    state: {
        token: null,
        username: null,
        email: null,
        adminId: null,
    },
    getters: {
        isAuthenticated: state => !!state.token,
        getToken: state => state.token,
        getUsername: state => state.username,
        getEmail: state => state.email,
        getAdminId: state => state.adminId,
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        clearToken(state) {
            state.token = null;
        },
        setUsername(state, username) {
            state.username = username;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setAdminId(state, adminId) {
            state.adminId = adminId;
        },
    },
    actions: {
        login({ commit }, token) {
            commit('setToken', token);
            localStorage.setItem('token', token);
        },
        logout({ commit }) {
            commit('clearToken');
            localStorage.removeItem('token');
        },
        setUsername({ commit }, username) {
            commit('setUsername', username);
        },
        setEmail({ commit }, email) {
            commit('setEmail', email);
        },
        setAdminId({ commit }, adminId) {
            commit('setAdminId', adminId);
        },
    },
}