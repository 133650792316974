export default {
    state: {
        settings: []
    },
    getters: {
        getSettings: state => state.settings,
        isMaintenanceActive: state => state.settings.maintenance_mode,
    },
    mutations: {
        setSettings(state, settings) {
            state.settings = settings;
        },
    },
    actions: {
        async fetchSettings({ commit }, token) {
            try {
                const response = await fetch(`${process.env.VUE_APP_API_URL}/settings`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    commit('setSettings', data);
                }
            }
            catch (error) {
                console.error(error);
            }
        },
    },
}